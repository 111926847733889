import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from "@merchx-v2/helpers";
import { useSiteMetadata } from "hooks/use-site-metadata";
import { navigate } from "utils/navigate";
import {
  Button,
  Footer,
  SelectWithLabel,
  Input,
  InputCvv,
  CreditCardInput,
  Notification,
  ExpiryDateInput,
  PreventDirectLink,
  FacebookPixels,
  Steps,
  SEO,
} from "components";
import { actionCreators, selectors } from "state/BillingPage/reducer";
import checkBlueIcon from "assets/checkBlue.svg";
import cvvImage from "assets/cvv.png";
import lockIcon from "assets/lock.svg";
import logoImage from "assets/fmfLogoBlack.png";
import Patterns from "patterns";
import {
  selectCartId,
  selectors as homeSelectors,
} from "state/HomePage/reducer";
import {
  actionCreators as loginActionCreators,
  selectors as loginSelectors,
} from "state/LoginPage/reducer";
import {
  addSubscriptionToCart,
  removeSubscriptionFromCart,
  takePayment,
} from "state/BillingPage/actions";
import * as styles from "./BillingPage.module.scss";

const BillingPage = (props) => {
  const {
    cartId,
    billingState,
    homeState,
    loginState,
    isCartContainsSubscription,
    memberEconomy,

    addSubscriptionToCart,
    removeSubscriptionFromCart,
    setMembershipAsTrial,
  } = props;

  const { shared, landing } = useSiteMetadata();

  // Lets define next page route
  let nextPagePath = "/thankyou";
  if (shared.HAS_UPSELLS_PAGE) {
    nextPagePath = "/upsell";
  }

  if (!shared.HAS_UPSELLS_PAGE && shared.HAS_STICKERS_PAGE) {
    nextPagePath = "/sticker";
  }

  if (
    !shared.HAS_UPSELLS_PAGE &&
    !shared.HAS_STICKERS_PAGE &&
    shared.HAS_UPGRADE_PAGE
  ) {
    nextPagePath = "/upgrade";
  }

  const countries = landing.COUNTRIES;

  const countriesRegions = {};

  const countriesOptions = Object.values(countries).map((country) => {
    countriesRegions[country.id] = country.regions.map((region) => ({
      value: region.id,
      label: region.name,
      autocomplete: region.iso2,
    }));

    return {
      value: country.id,
      label: country.name,
      autocomplete: country.name,
    };
  });

  const paymentApiGateway = {
    authorize: landing.PAYMENT_SETTINGS.apiGateway,
  };

  const paymentPluginSettings = {
    type: landing.PAYMENT_PLUGIN,
    apiLoginId: landing.PAYMENT_SETTINGS.apiLoginId,
    clientKey: landing.PAYMENT_SETTINGS.clientKey,
  };

  const [isAddSubscriptionChecked, setIsAddSubscriptionChecked] = useState(
    false
  );
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [notification, showNotification] = useState();

  const [selectedCountryOption, setSelectedCountryOption] = useState(
    countriesOptions[0]
  );
  const [regionsOptions, setRegionOptions] = useState(
    countriesRegions[countriesOptions[0].value]
  );

  const isNonMember = loginState.membershipInfo.status === "ANONYMOUS";
  const isFreeMember = loginState.membershipInfo.status === "FREE";
  const isActiveMember = ["ACTIVE", "TRIAL", "EXPIRING"].includes(
    loginState.membershipInfo.status
  );
  const isExpiredMember = loginState.membershipInfo.status === "EXPIRED";

  useEffect(() => {
    showNotification("Product added to cart");
    trackingGA();
  }, []);

  useEffect(() => {
    setIsAddSubscriptionChecked(isCartContainsSubscription);
  }, [isCartContainsSubscription]);

  useEffect(() => {
    showNotification(billingState.UIState.error);
  }, [billingState.UIState.error]);

  const countryChangedHandler = (selectedCountryOption) => {
    setRegionOptions(countriesRegions[selectedCountryOption.value]);
    setSelectedCountryOption(selectedCountryOption);
    props.setCountry(selectedCountryOption);
    props.setState("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsShowErrors(true);
    if (billingState.isValid) {
      const payload = await props.takePayment({
        paymentPluginSettings,
        paymentApiGateway,
      });
      if (payload?.payload?.isSaved) {
        if (isAddSubscriptionChecked) {
          await setMembershipAsTrial();
        }
        navigate(nextPagePath);
        return true;
      }
    }
  };

  const handleBecameVipMemberClicked = async ({ target: { checked } }) => {
    setIsAddSubscriptionChecked(checked);
    if (checked) {
      await addSubscriptionToCart({ landingId: landing.LANDING_ID });
    } else {
      await removeSubscriptionFromCart();
    }
  };

  const mainProduct = homeState.prices.find(
    (productPrice) =>
      homeState.cart.items?.length &&
      homeState.cart.items[0].sku === productPrice.sku
  );

  return (
    <div className={styles.billingPage}>
      <SEO pathname="/billing" />
      <PreventDirectLink cartId={cartId} />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event="AddPaymentInfo" />
      <form onSubmit={handleSubmit} className={styles.content}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logoImage} alt="fmf" />
        </div>
        <Steps step={3} name="Choose" />
        <p className={styles.title}>Billing Information</p>

        <div className={styles.cardInfo}>
          <div className={styles.cardNumber}>
            <CreditCardInput
              placeholder="XXXX-XXXX-XXXX-XXXX"
              onChange={props.setCardNumber}
              label="Credit or Debit card number"
              isValid={billingState.validation.cardNumber.isValid}
              isShowErrors={isShowErrors}
              errorMessage={billingState.validation.cardNumber.error}
            />
          </div>
          <div className={styles.cardInfoRow}>
            <div className={styles.expiryDate}>
              <ExpiryDateInput
                placeholder="MM/YY"
                label="Expiry Date"
                pattern={Patterns.expiry}
                onChange={props.setExpiryDate}
                isValid={billingState.validation.expiryDate.isValid}
                isShowErrors={isShowErrors}
                errorMessage={billingState.validation.expiryDate.error}
              />
            </div>
            <div className={styles.cardInfoCvv}>
              <InputCvv
                placeholder="***"
                label="CVV"
                onChange={props.setCvv}
                isValid={billingState.validation.cvv.isValid}
                isShowErrors={isShowErrors}
                errorMessage={billingState.validation.cvv.error}
              />
            </div>
            <div className={styles.cardInfoImage}>
              <img src={cvvImage} alt="" />
              <div className={styles.cardInfoImageText}>
                CVV is the last 3 digits on the back of your credit card
              </div>
            </div>
          </div>
        </div>

        <div className={styles.shippingAsBillingBox}>
          <div className={styles.checkboxContainer}>
            <input
              className={styles.checkbox}
              id="checkbox"
              type="checkbox"
              name="check"
              value="check1"
              onClick={() =>
                props.setIsBillingTheSameAsShipping(
                  !billingState.isBillingTheSameAsShipping
                )
              }
              defaultChecked={billingState.isBillingTheSameAsShipping}
            />
            <label className={styles.checkboxLabel} htmlFor="checkbox">
              Use shipping address as billing address
            </label>
          </div>
        </div>

        {(isNonMember || isFreeMember || isExpiredMember) && (
          <div className={styles.membershipBox}>
            <div className={styles.checkboxContainer}>
              <input
                className={styles.checkbox}
                id="checkboxBecameVip"
                type="checkbox"
                checked={isAddSubscriptionChecked}
                onChange={handleBecameVipMemberClicked}
              />
              <label
                className={styles.checkboxLabel}
                htmlFor="checkboxBecameVip"
              >
                Save ${memberEconomy?.totalSaved} by becoming a VIP member
              </label>
            </div>
            <div className={styles.membershipDescription}>
              You will save ${memberEconomy?.totalSaved} on every order by
              becoming a VIP member. Enjoy all the benefits{" "}
              {!isExpiredMember && <>with a {"  "} FREE 14-day trial</>} today!
              Only ${memberEconomy?.subscriptionPrice}/month
              {!isExpiredMember && <> after trial ends</>}. Cancel any time.
            </div>
          </div>
        )}

        {!billingState.isBillingTheSameAsShipping && (
          <div className={styles.formContainer}>
            <div className={styles.formItemRow}>
              <div className={styles.formItemFirstName}>
                <Input
                  placeholder="First Name"
                  label="First Name"
                  onChange={props.setFirstName}
                  isValid={billingState.validation.firstName}
                  isShowErrors={isShowErrors}
                  errorMessage="Please input your First Name"
                  defaultValue={billingState.firstName}
                  name="first-name"
                  autoComplete="name first-name"
                />
              </div>
              <div className={styles.formItemLastName}>
                <Input
                  placeholder="Last Name"
                  label="Last Name"
                  onChange={props.setLastName}
                  isValid={billingState.validation.lastName}
                  isShowErrors={isShowErrors}
                  errorMessage="Please input your Last Name"
                  defaultValue={billingState.lastName}
                  name="last-name"
                  autoComplete="name last-name"
                />
              </div>
            </div>
            <div className={styles.formItemRow}>
              <div className={styles.formItemAddress}>
                <Input
                  placeholder="Address 1"
                  label="Address"
                  onChange={props.setAddress}
                  isValid={billingState.validation.address}
                  isShowErrors={isShowErrors}
                  errorMessage="Please input your Address"
                  defaultValue={billingState.address}
                  name="street-address"
                  autoComplete="shipping street-address"
                />
              </div>
            </div>
            <div className={styles.formItemRow}>
              <div className={styles.formItemAddress}>
                <Input
                  placeholder="Address 2"
                  label="Address 2"
                  onChange={props.setAddress2}
                  isShowErrors={false}
                  errorMessage="Please input your Address 2"
                  defaultValue={billingState.address2}
                />
              </div>
            </div>
            <div className={styles.formItemRow}>
              <div className={styles.formItemCity}>
                <Input
                  placeholder="City"
                  label="City"
                  onChange={props.setCity}
                  isValid={billingState.validation.city}
                  isShowErrors={isShowErrors}
                  errorMessage="Please input your City"
                  defaultValue={billingState.city}
                  name="city"
                  autoComplete="shipping address-level2"
                />
              </div>
            </div>
            <div className={styles.formItemSelectCountry}>
              <SelectWithLabel
                placeholder="United States of America"
                label="Country"
                onSelect={countryChangedHandler}
                isValid={billingState.validation.country}
                isShowErrors={isShowErrors}
                errorMessage="Please select your Country"
                value={selectedCountryOption}
                options={countriesOptions}
              />
            </div>
            <div className={styles.formItemRow}>
              <div className={styles.formItemZip}>
                <Input
                  placeholder="Zip Code"
                  label="Zip Code"
                  onChange={props.setZipCode}
                  isValid={billingState.validation.zipCode.isValid}
                  isShowErrors={isShowErrors}
                  errorMessage={billingState.validation.zipCode.error}
                  defaultValue={billingState.zipCode}
                  name="postal-code"
                  autoComplete="shipping postal-code"
                />
              </div>
              <div className={styles.formItemState}>
                <SelectWithLabel
                  placeholder="Select State"
                  label="State"
                  onSelect={props.setState}
                  isValid={billingState.validation.state}
                  isShowErrors={isShowErrors}
                  errorMessage="Please select your State"
                  options={regionsOptions}
                  value={billingState.stateOption}
                  inputProps={{
                    name: "state",
                    autoComplete: "shipping address-level1",
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className={styles.productContainer}>
          <div className={styles.productInfoContainer}>
            <div className={styles.productInfoTitle}>
              <p className={styles.orderSummary}>Order Summary</p>
              {isActiveMember && (
                <div className={styles.discountBox}>
                  <p className={styles.discountText}>VIP Member Discount</p>
                  <img
                    className={styles.checkBlueIcon}
                    src={checkBlueIcon}
                    alt="&check;"
                  />
                </div>
              )}
            </div>
            <div className={styles.productInfoSubtotal}>
              <p className={styles.subtotalText}>
                {mainProduct?.displayName || "Champ MVP Black T-Shirt"}
              </p>
              {(isNonMember || isFreeMember || isExpiredMember) && (
                <p className={styles.subtotalSumBlack}>${homeState.cart.sum}</p>
              )}
              {isActiveMember && (
                <div className={styles.subtotalSumBox}>
                  <p className={styles.subtotalSumBlackLineThrough}>
                    ${memberEconomy?.retailPrice}
                  </p>
                  <p className={styles.subtotalSumRed}>
                    ${memberEconomy?.memberPrice}
                  </p>
                </div>
              )}
            </div>
            {isNonMember && isAddSubscriptionChecked && (
              <div className={styles.productInfoSubtotal}>
                <p className={styles.subtotalText}>VIP Club Trial</p>
                <p className={styles.subtotalSumRed}>FREE</p>
              </div>
            )}
            <div className={styles.productInfoSubtotal}>
              <p className={styles.subtotalText}>Est. Shipping</p>
              <p className={styles.subtotalSumBlack}>
                ${homeState.cart.shipping}
              </p>
            </div>
            <div className={styles.total}>
              <p className={styles.subtotalText}>Total</p>
              <p className={styles.subtotalSumBlackStrong}>
                ${homeState.cart.totalWithoutProcessing}
              </p>
            </div>
          </div>

          <div className={styles.button}>
            <Button
              value="Complete Checkout"
              loading={billingState.UIState.isLoading}
            />
          </div>

          <div className={styles.secureInfo}>
            <img className={styles.secureIcon} src={lockIcon} alt="" />
            <p className={styles.secureInfoText}>Secure Checkout</p>
          </div>
        </div>

        {/* {(isNonMember || isFreeMember) && (
          <div className={styles.tryContainer}>
            <p className={styles.tryTitle}>Try Fan Made Fits VIP FREE for 14 days.</p>
            <p className={styles.tryText}>
              By claiming your free t-shirt you agree to the terms of this promotion. You agree to pay ${homeState.cart.shipping} for s/h and ${homeState.cart.processing} for p/h
              and start your VIP Club trial. After your 14 day free trial ends, a monthly recurring charge of ${memberEconomy?.subscriptionPrice} will be automatically charged to
              your card. To avoid charges, cancel before your trial ends.
            </p>
          </div>
        )} */}
      </form>
      <div className={styles.footerContainer}>
        <Footer />
        <Notification message={notification} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartId: selectCartId(state),
  homeState: homeSelectors.selectHomeState(state),
  memberEconomy: homeSelectors.selectMemberEconomyDetails(state),
  billingState: selectors.selectBillingState(state),
  loginState: loginSelectors.selectLoginState(state),
  isCartContainsSubscription: homeSelectors.selectIsCartContainsSubscription(
    state
  ),
});

const mapDispatchToProps = {
  addSubscriptionToCart,
  removeSubscriptionFromCart,
  setCardNumber: actionCreators.setCardNumber,
  setExpiryDate: actionCreators.setExpiryDate,
  setCvv: actionCreators.setCvv,
  setFirstName: actionCreators.setFirstName,
  setLastName: actionCreators.setLastName,
  setAddress: actionCreators.setAddress,
  setAddress2: actionCreators.setAddress2,
  setCity: actionCreators.setCity,
  setCountry: actionCreators.setCountry,
  setZipCode: actionCreators.setZipCode,
  setState: actionCreators.setState,
  setIsBillingTheSameAsShipping: actionCreators.setIsBillingTheSameAsShipping,
  takePayment: takePayment,
  setMembershipAsTrial: loginActionCreators.setMembershipAsTrial,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);
